input {
  border: solid 1px #eee;
  padding: 3px;
}
.p1 {
  font-size: 1.5rem;
  line-height: 1.416666666666667;
}
.p2 {
  font-size: 1.32rem;
  line-height: 1.476190476190476;
}
.p3 {
  font-size: 1.13rem;
  line-height: 1.555555555555556;
}
.p4 {
  font-size: 1rem;
  line-height: 1.625;
}
.p5 {
  font-size: 0.88rem;
  line-height: 1.714285714285714;
}
.p6 {
  font-size: 0.75rem;
  line-height: 1.833333333333333;
}
.clear {
  clear: both;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 16px;
  line-height: 22px;
}
html,
body {
  height: 100%;
}
body {
  font-size: 0.88rem;
  line-height: 1.714285714285714;
  text-size-adjust: none;
  position: relative;
}
li {
  list-style-position: inside;
}
a,
a:visited {
  color: #14558e;
}
.hide {
  display: none;
}
.txt--r {
  text-align: right;
}
.c-card {
  position: relative;
  background-color: #fff;
  color: #000;
  border-radius: 15px;
  padding: 40px 50px;
  margin: 20px 0;
}
.c-card h1 {
  margin-bottom: 0;
}
.c-card h2 {
  font-size: 22px;
  font-weight: 600;
  margin: 20px 0 5px 0;
}
.c-card.c-card--reverse {
  background-color: rgba(0,0,0,0.4);
  color: #fff;
}
.c-chart {
  position: relative;
}
@media (max-width: 375px) {
  .c-chart__xAxis text {
    font-size: 0.6em;
  }
}
.c-chart__graph {
  width: 80vw;
  height: 70vh;
  min-height: 500px;
}
.c-chart__legend {
  font-size: 0.75rem;
  line-height: 1.833333333333333;
  position: relative;
  width: 100%;
  margin-top: 40px;
}
.c-chart__legend g {
  display: block;
}
.c-chart__legend svg {
  position: relative;
  width: 100%;
  top: 20px;
}
@media (min-width: 1200px) {
  .c-chart__legend svg {
    position: absolute;
    margin-left: 9%;
    height: 90px;
  }
}
@media (max-width: 1024px) {
  .c-chart__legend svg {
    font-size: 0.75rem;
    line-height: 1.833333333333333;
  }
}
.c-chart input {
  width: 80px;
}
.c-chart__bars .c-chart__total {
  font-size: 0.8em;
  fill: #807e7e;
}
.c-chart__bars text {
  font-size: 0.55em;
  fill: #fff;
  font-weight: bold;
}
.c-chart__bars rect:hover {
  cursor: pointer;
}
.c-chart__bars rect.is-active {
  stroke: #0f0;
  stroke-width: 2px;
}
.c-chart__bars.is-inactive rect {
  opacity: 0.2;
}
.c-chart__bars.is-active rect {
  opacity: 1;
}
.c-container {
  width: 90%;
  margin: auto;
}
.c-tooltip {
  display: none;
  position: absolute;
  z-index: 100;
  padding: 20px;
  background-color: #fff;
  border: solid 1px #eee;
  border-radius: 10px;
}
.c-tooltip.is-active {
  display: block;
}
.c-tooltip__close {
  position: absolute;
  right: 10px;
  top: 0;
}
.c-tooltip__close:hover {
  cursor: pointer;
}
